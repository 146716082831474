import { graphql } from 'gatsby';
import * as React from 'react';
import Hero from '../components/chapter/Hero';
import Section from '../components/home/Section';
import Layout from '../Layout';

const IndexPage = ({ data }) => {
  const {
    title,
    sections,
    subtitle,
    title_svg,
    texture,
    lede,
    illustration,
    illustration_caption,
    color,
  } = data.strapiHomepage;

  sections.map((section) => {
    section.pages = data[`allStrapi${section.linksTo}`].nodes;
    return section;
  });
  return (
    <Layout title={'Home'} seoImage={illustration}>
      <Hero
        title={title}
        subtitle={subtitle}
        titleSvg={title_svg}
        texture={texture}
        lede={lede}
        illustration={illustration}
        illustrationCaption={illustration_caption}
        color={color}
      />
      {sections.map(
        (
          { heading, description, image, landscape_image, color, pages },
          index
        ) => (
          <Section
            key={index}
            title={heading}
            description={description}
            image={image}
            sideImg={landscape_image}
            color={color}
            pages={pages}
          />
        )
      )}
    </Layout>
  );
};

export const query = graphql`
  {
    strapiHomepage {
      title
      subtitle
      lede
      title_svg {
        localFile {
          svgData
        }
      }
      texture {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
          }
        }
      }
      illustration {
        alternativeText
        width
        height
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            seo: gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
      }
      illustration_caption
      color {
        accent
        theme
      }
      sections {
        heading
        description
        color {
          accent
          theme
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
        landscape_image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 60
                placeholder: NONE
              )
            }
          }
        }
        linksTo
      }
    }
    allStrapiAbout {
      nodes {
        title
        slug
        summary
        preview_img {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
      }
    }
    allStrapiVisitPages {
      nodes {
        title
        slug
        summary
        preview_img {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
      }
    }
    allStrapiChapters(
      sort: { fields: order, order: ASC }
      filter: { isSubChapter: { eq: false } }
    ) {
      nodes {
        title
        slug
        summary
        preview_img {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
