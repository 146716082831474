// extracted by mini-css-extract-plugin
export var bgContainer = "Section-module--bgContainer--1w9lE";
export var sectionContainer = "Section-module--sectionContainer--lHSzM";
export var desktopLeft = "Section-module--desktopLeft--1Kq77";
export var fullHeight = "Section-module--fullHeight--N4q3Q";
export var imgFitHeight = "Section-module--imgFitHeight--rtPrg";
export var textWrapper = "Section-module--textWrapper--1vUyp";
export var textContainer = "Section-module--textContainer--3PA41";
export var titleText = "Section-module--titleText--31mxD";
export var titleTexture = "Section-module--titleTexture--12zjg";
export var gradientOverlay = "Section-module--gradientOverlay--mafXU";
export var pagesContainer = "Section-module--pagesContainer--1hwgm";
export var descContainer = "Section-module--descContainer--2t7qD";
export var descImage = "Section-module--descImage--2NmKf";
export var descImgWrapper = "Section-module--descImgWrapper--ldOIS";
export var pageWrapper = "Section-module--pageWrapper--1hbx-";