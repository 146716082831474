import React, { useEffect, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import parse from 'html-react-parser';
import PageLink from './PageLink';
import {
  bgContainer,
  descContainer,
  descImage,
  descImgWrapper,
  desktopLeft,
  fullHeight,
  imgFitHeight,
  pagesContainer,
  pageWrapper,
  sectionContainer,
  textContainer,
  textWrapper,
  titleText,
  titleTexture,
} from './Section.module.scss';
gsap.registerPlugin(ScrollTrigger);

const Section = ({ title, description, image, sideImg, color, pages }) => {
  if (color === null) color = { accent: 'Lemon', theme: 'Light' };
  const { accent, theme } = color;

  const containerRef = useRef();
  const titleContainerRef = useRef();
  const imgContainerRef = useRef();
  const imgRefs = useRef([]);
  const addToImgRefs = (img) => {
    if (img && !imgRefs.current.includes(img)) {
      imgRefs.current.push(img);
    }
  };

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        id: `pinImg`,
        trigger: containerRef.current,
        start: 'top top',
        end: 'bottom bottom',
        toggleActions: 'play none none reverse',
        scrub: 1,
        pin: imgContainerRef.current,
        pinSpacing: false,
        //markers: true,
      },
    });
    gsap.timeline({
      scrollTrigger: {
        id: `pinTitle`,
        trigger: containerRef.current,
        start: 'top top',
        end: 'bottom bottom',
        toggleActions: 'play none none reverse',
        scrub: 1,
        pin: titleContainerRef.current,
        pinSpacing: false,
        //markers: true,
      },
    });

    imgRefs.current.forEach((img) => {
      const id = img.id;
      const imgTl = gsap.timeline({
        scrollTrigger: {
          id: `fade-img-${id}`,
          trigger: img,
          // start: 'center-=100px center',
          // end: 'center center',
          start: 'top bottom-=100px',
          end: 'top+=100px bottom-=100px',
          toggleActions: 'play none none reverse',
          scrub: 1,
          // markers: true,
        },
      });
      const gsapSelector = gsap.utils.selector(imgContainerRef.current);
      imgTl
        .set(gsapSelector(`div.img-${id}`), { opacity: 0 })
        .to(gsapSelector(`div.img-${id}`), {
          opacity: 1,
          duration: 0.5,
          ease: 'power1.inOut',
        });
    });

    return () => {
      // mainTl.kill();
      // tlRef.current.kill();
    };
  }, []);

  return (
    <div className={`bg${theme} ${bgContainer}`}>
      <div className={`${sectionContainer} posRel`} ref={containerRef}>
        <div
          className={`${desktopLeft} ${fullHeight} posRel`}
          ref={imgContainerRef}
        >
          {image && (
            <GatsbyImage
              image={getImage(image.localFile.childImageSharp)}
              alt={image.alternativeText}
              className={`${imgFitHeight}`}
              loading="eager"
            />
          )}
          {pages?.map(({ preview_img, slug }, index) => (
            <GatsbyImage
              key={index}
              image={getImage(preview_img?.localFile.childImageSharp)}
              alt={preview_img?.alternativeText}
              className={`${imgFitHeight} img-${slug}`}
              style={{ opacity: 0 }}
            />
          ))}
        </div>

        <div className={`posAbs ${textWrapper}`}>
          <div className={`${textContainer}`} ref={titleContainerRef}>
            <div className={`linedPaper ${titleTexture}`} />
            <div className={`${titleText} varColor${accent} textColorDune85`}>
              <h2 className={`h1`}>{title}</h2>
            </div>
          </div>
        </div>

        <div className={`${pagesContainer} relPos`}>
          <div className={`bg${theme} ${descContainer}`}>
            {sideImg && (
              <div className={`${descImgWrapper} varColor${accent}`}>
                <GatsbyImage
                  image={getImage(sideImg?.localFile.childImageSharp)}
                  alt={sideImg?.alternativeText}
                  className={`bgLight`}
                  imgClassName={`${descImage}`}
                  loading="eager"
                />
              </div>
            )}
            {description && (
              <span className={`lede strong`}>{parse(description)}</span>
            )}
          </div>
          {pages?.map(({ title, summary, slug }, index) => (
            <div
              ref={addToImgRefs}
              id={`${slug}`}
              key={slug}
              className={`${pageWrapper} bg${theme}`}
            >
              <PageLink
                title={title}
                summary={summary}
                slug={slug}
                slugText={'Explore'}
                accent={accent}
                theme={theme}
                isEven={index % 2}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
